import { extendTheme, theme } from '@chakra-ui/react';

const luciBrandColors = {
  50: '#ffe7f3',
  100: '#f6bed7',
  200: '#eb93bf',
  300: '#e26aa9',
  400: '#d94095',
  500: '#bf2782',
  600: '#951d69', //default
  700: '#6c1445',
  800: '#420926',
  900: '#1b000d',
};

const aureliusBrandColors = {
  50: '#ffe7e9',
  100: '#f5bfc3',
  200: '#e8969d',
  300: '#de6e76',
  400: '#d3464f',
  500: '#b92c36',
  600: '#91212a', //default
  700: '#69171e',
  800: '#400b11',
  900: '#1c0103',
};

const albanusBrandColors = {
  50: '#e5efff',
  100: '#bbcef9',
  200: '#8faef0',
  300: '#638ee9',
  400: '#396de2',
  500: '#2154c8',
  600: '#18419c',
  700: '#0f2f70', //default
  800: '#061c45',
  900: '#00091b',
};

const hufelandBrandColors = {
  50: '#ffe5ff',
  100: '#f8baf3',
  200: '#f18de9',
  300: '#eb61de',
  400: '#e436d4',
  500: '#ca1dbb',
  600: '#9e1492', //default
  700: '#720d69',
  800: '#460641',
  900: '#1b0019',
};

const wasserflueBrandColors = {
  50: '#fffedb',
  100: '#fef9ad',
  200: '#fdf57e',
  300: '#fdf04d',
  400: '#fcec1c', //default
  500: '#e3d303',
  600: '#b0a400',
  700: '#7e7500',
  800: '#4c4600',
  900: '#1a1700',
};

const pakBrandColors = {
  50: '#ffe5e5',
  100: '#f9bcbc',
  200: '#ee9191',
  300: '#e66666',
  400: '#dd3b3b',
  500: '#c42222', //default
  600: '#991819',
  700: '#6e1012',
  800: '#440708',
  900: '#1e0000',
};

const gereonBrandColors = {
  50: '#d8fbff',
  100: '#abecff',
  200: '#7adeff',
  300: '#48d0ff',
  400: '#1ac2ff',
  500: '#00a9e6',
  600: '#0083b4',
  700: '#005e82',
  800: '#003951',
  900: '#001520',
};

const schunkMeierBrandColors = {
  50: '#dafbff',
  100: '#b1ebfc',
  200: '#86dcf6',
  300: '#58cdf0',
  400: '#2ebfea',
  500: '#15a5d1',
  600: '#0280a4',
  700: '#005c76',
  800: '#003849',
  900: '#00141d',
};

const pacuraBrandColors = {
  50: '#e1f9fb',
  100: '#c5e6e9',
  200: '#a5d3d8',
  300: '#84c2c8', //default
  400: '#64b1b7',
  500: '#4a979d',
  600: '#37767b',
  700: '#255458',
  800: '#113336',
  900: '#001316',
};

const sand = {
  50: '#f4f3f1', //default
  100: '#dadada',
  200: '#c2c1c1',
  300: '#aba9a6',
  400: '#94908a',
  500: '#7b7771',
  600: '#5f5c58',
  700: '#434240',
  800: '#292826',
  900: '#0e0d0b',
};

const baseTheme = {
  ...extendTheme({
    fonts: {
      heading: 'Open Sans, sans-serif',
      body: 'Open Sans, sans-serif',
    },
    shadows: {
      outline: '0 0 0 2px var(--chakra-colors-sand-300)',
    },
  }),
  colors: {
    brand: luciBrandColors,
    blackAlpha: theme.colors.blackAlpha,
    whiteAlpha: theme.colors.whiteAlpha,
    gray: sand,
    sand: sand,
    red: {
      50: '#ffe7e7',
      100: '#f5c0c0',
      200: '#e89697',
      300: '#dd6e6e', //default
      400: '#d34646',
      500: '#b92c2c',
      600: '#912222',
      700: '#691717',
      800: '#400b0c',
      900: '#1c0101',
    },
    warning: {
      50: '#fff3de',
      100: '#fbddb5',
      200: '#f5c689',
      300: '#f0af5b', //default
      400: '#ec982f',
      500: '#d27e16',
      600: '#a4620f',
      700: '#754609',
      800: '#472901',
      900: '#1c0d00',
    },
    green: {
      50: '#e7fae6',
      100: '#c7ecc3',
      200: '#a3dea0',
      300: '#80d17b', //default
      400: '#5dc456',
      500: '#44aa3c',
      600: '#34842e',
      700: '#245e20',
      800: '#133911',
      900: '#001500',
    },
  },
};

export const createTheme = (hostname: string) => {
  if (hostname.includes('aurelius-hof.luciapp.de')) {
    return {
      ...baseTheme,
      colors: {
        ...baseTheme.colors,
        brand: aureliusBrandColors,
      },
    };
  }
  if (hostname.includes('haus-albanus.luciapp.de')) {
    return {
      ...baseTheme,
      colors: {
        ...baseTheme.colors,
        brand: albanusBrandColors,
      },
    };
  }
  if (hostname.includes('hufeland.luciapp.de')) {
    return {
      ...baseTheme,
      colors: {
        ...baseTheme.colors,
        brand: hufelandBrandColors,
      },
    };
  }
  if (hostname.includes('wasserflue.luciapp.de')) {
    return {
      ...baseTheme,
      colors: {
        ...baseTheme.colors,
        brand: wasserflueBrandColors,
      },
    };
  }
  if (hostname.includes('pak.luciapp.de')) {
    return {
      ...baseTheme,
      colors: {
        ...baseTheme.colors,
        brand: pakBrandColors,
      },
    };
  }
  if (hostname.includes('st-gereon.luciapp.de')) {
    return {
      ...baseTheme,
      colors: {
        ...baseTheme.colors,
        brand: gereonBrandColors,
      },
    };
  }
  if (hostname.includes('schunk-meier.luciapp.de')) {
    return {
      ...baseTheme,
      colors: {
        ...baseTheme.colors,
        brand: schunkMeierBrandColors,
      },
    };
  }
  if (hostname.includes('pacura-med.luciapp.de')) {
    return {
      ...baseTheme,
      colors: {
        ...baseTheme.colors,
        brand: pacuraBrandColors,
      },
    };
  }

  return baseTheme;
};
