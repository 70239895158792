import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { createStore } from 'redux';

type state = {
  sidebarShow: 'responsive' | boolean;
  asideShow: boolean;
  darkMode: boolean;
};

const initialState: state = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
};

type args = { type?: string; [key: string]: any };

const changeState = (oldState = initialState, { type, ...rest }: args) => {
  switch (type) {
    case 'set':
      return { ...oldState, ...rest };
    default:
      return oldState;
  }
};

const store = createStore(changeState);
export default store;

// https://react-redux.js.org/using-react-redux/static-typing#typing-the-useselector-hook
export const useTypedSelector: TypedUseSelectorHook<state> = useSelector;
