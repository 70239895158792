// import './scss/style.scss';

import { ChakraProvider } from '@chakra-ui/react';
import { createTheme } from 'theme/theme';
import { NewWebAdminInfo } from 'components/NewWebAdminInfo';

const App = () => {
  return (
    <ChakraProvider theme={createTheme(window.location.hostname)}>
      <NewWebAdminInfo />
    </ChakraProvider>
  );
};

export default App;
