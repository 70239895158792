import { Box, Flex, Link, Text } from '@chakra-ui/react';

const SUBDOMAIN_TO_TENANT = {
  'haus-albanus': 'albanus',
  'st-gereon': 'gereon',
  'hp-lvr': 'hplvr',
  'pacura-med': 'pacura',
};

const getTenantName = () => {
  const host = window.location.host;
  const subdomain = host.substring(0, host.indexOf('.')) as keyof typeof SUBDOMAIN_TO_TENANT;
  if (!subdomain) {
    return 'dev';
  }
  const tenant = SUBDOMAIN_TO_TENANT[subdomain];
  if (!tenant) {
    return subdomain;
  }
  return tenant;
};

export const NewWebAdminInfo = () => {
  return (
    <Flex minH="100vh" align="center" justify="center" flexDirection="column" bg="sand.50">
      <Flex align="center" justify="start" flexDirection="column" mb="8" mt="-20">
        <Box
          maxW="md"
          w="full"
          experimental_spaceY="4"
          bg="white"
          px="8"
          py="8"
          rounded="md"
          shadow="sm"
          border="1px solid"
          borderColor="sand.100"
        >
          Sehr geehrte Damen und Herren,
          <br />
          <br /> durch die Migration zur Open Telekom Cloud wurde der Webadmin Bereich auf eine neue
          Adresse verlegt: <br />
          <br />
          <Link href="https://webadmin.luciapp.de" color="blue">
            https://webadmin.luciapp.de
          </Link>
          <br />
          <br /> Bitte nutzen Sie fortan nur noch diese URL mit Ihrer Firmen-ID:{' '}
          <span style={{ fontSize: '1.4em', fontWeight: 'bold' }}>{getTenantName()} </span>
          <br />
          <br /> Vielen Grüße
          <br /> Ihr LUCI-Team
        </Box>
      </Flex>
    </Flex>
  );
};
